<template>
  <v-card>
    <Header :title="$t('profile.heading')"  >
    </Header>

    <v-container class grey lighten-4>
    <v-card-title class="subtitle">{{$t(tenant.nameEventsAsChallenges ? 'profile.challenges-joined' : 'profile.events-joined')}}</v-card-title>
    <v-card-text v-if="!profile">
      <v-progress-circular indeterminate />
    </v-card-text>
    <v-card-text v-if="profile && (!profile.events || profile.events == 0)">
      <p class="mb-0">{{$t('profile.events-joined-none')}}</p>
    </v-card-text>      
    <div v-else>
    <v-row dense v-if="profile && profile.events" class="mx-4 mb-4">
      <template v-for="(item, idx) in (profile.events)">
        <v-col 
          :key="item.o ? item.o.id : item.id"
          cols="12"
          sm="6"
          md="4"
          lg="3"
        >
          <EventSummaryCard :summary="item" :index="idx" forceLoadResults />
        </v-col>
      </template>
    </v-row>
  </div>
    </v-container>

  </v-card>
</template>



<script>
import { mapGetters } from "vuex";
import eventService from "@/services/eventService";
import profileService from "@/services/profileService";
import { EventBus } from '@/plugins/eventbus.js';
import { getAuth, signOut } from "firebase/auth";
import { DateTime } from "luxon";
import EventSummaryCard from '@/components/profile/EventSummaryCard.vue';
import Header from './_Header.vue';
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "ProfileEvents",
  components: {
    EventSummaryCard,
    Header,
  },
  props: {
  },
  data() {
    return {
      tenant: tenant,
      now: DateTime.now(),
      DateTime: DateTime,
      profile: null,
      results: null,
      ads: null,
      featured: null,
      providerUrls: null,
    };
  },
  async mounted() {
    await this.getProfile();
    
    EventBus.$on('login-state-change', async user => {
      await this.getProfile();
    });
  },
  methods: {
    async udateAvatar() {
      await this.getProfile();
    },

    async signOut() {
      const auth = getAuth();
      await signOut(auth)
      this.$store.dispatch('setUser', null);
      this.$router.replace({ name: "home" });
    },

    async getProfile() {
      if (this.user) {
        try{
          var response = await profileService.get(/*full:*/false);
          //var response = {data:{}};
        }
        catch {
          // network error occur after api has been deployed causing tokens to be invalidated
          // reload the page to prevent empty profile page from showing
          //window.location.reload(true);
          return;
        }
        this.profile = response.data;
      }
    },
  },
  computed: {
    ...mapGetters({
      user: "user"
    })
  },

};
</script>
<style lang="scss">
  
</style>

